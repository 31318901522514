@import "styles/variable";

.numerals {
  display: flex;
  padding: 85px 80px 0;
  width: 100%;
  margin: 0 auto -360px;
  max-width: 1440px;
  .item {
    width: 25%;
    height: 651px;
    background: linear-gradient(180deg, #1E324B 0%, #345471 100%);
    position: relative;
    padding: 0 30px 65px;
    color: #fff;
    top: 80px;
    transition: 1s;
    opacity: 0;
    &:not(:first-child) {
      &::before {
        content: '';
        position: absolute;
        width: 1px;
        height: 60%;
        background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        top: 0;
        left: 0;
        opacity: .4;
      }
    }
    &:first-child {
      padding-left: 0;
      &::before {
        content: '';
        background: linear-gradient(
                        180deg
                , #1E324B 0%, #345471 100%);
        position: absolute;
        height: 100%;
        right: 100%;
        top: 0;
        width: 300%;
      }
    }
    &:nth-child(2) {
      margin-top: -45px;
    }
    &:nth-child(3) {
      margin-top: -90px;
    }
    &:nth-child(4) {
      margin-top: -143px;
      &::after {
        content: '';
        background: linear-gradient(
                        180deg
                , #1E324B 0%, #345471 100%);
        position: absolute;
        height: 100%;
        left: 100%;
        top: 0;
        width: 300%;
      }
    }
    .entitled {
      padding-top: 52px;
    }
  }
  .animation {
    transition: 1s;
    top: 0;
    padding-top: 65px;
    opacity: 1;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
    color: $color-yellow-light;
    padding-top: 12px;
    border-top: 1px solid $color-yellow-light;
    margin-bottom: 14px;
    display: inline-block;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .text {
    max-width: 186px;
  }
  .numeral {
    font-size: 56px;
    font-weight: 400;
    font-family: $font-geo;
    margin-right: 16px;
  }
  .img {
    display: block;
    margin: 0 auto;
  }
  .second {
    margin-top: 34px;
  }
  .third {
    margin-top: 81px;
  }
  .fourth {
    margin-top: 76px;
  }
}

@media all and (max-width: 1439px) {
  .numerals {
    max-width: 1168px;
    .text {
      max-width: 100%;
      width: 100%;
    }
  }
}

@media all and (max-width: 1168px) {
  .numerals {
    padding: 85px 40px 0;
    .numeral {
      font-size: 42px;
    }
  }
}

@media all and (max-width: 940px) {
  .numerals {
    margin-bottom: -104px;
    max-width: 100%;
    padding: 85px 0 0;
    .item {
      height: 417px;
      &:nth-child(1) {
        padding-left: 30px;
      }
      &:nth-child(2) {
        height: 462px;
      }
      &:nth-child(3) {
        height: 507px;
      }
      &:nth-child(4) {
        height: 560px;
      }      
    }
    .img {
      position: absolute;
      bottom: 193px;
    }
    .second {
      left: calc(50% - 35px);
    }
    .third {
      left: calc(50% - 52px);
    }
    .fourth {
      left: calc(50% - 50px);
    }
    .title {
      font-size: 12px;
    }
    .numeral {
      font-size: 36px;
      margin-right: 0;
    }
    .text {
      font-size: 11px;
    }
  }
}

@media all and (max-width: 700px) {
  .numerals {
    flex-wrap: wrap;
    padding-top: 0;
    position: relative;
    z-index: 1;
    top: -4px;
    margin-bottom: 0;
    .numeral {
      margin: 0 auto 7px;
    }
    .title {
      border-top: 0;
    }
    .img {
      bottom: 40px;
    }
    .item {
      width: 50%;
      height: 240px;
      padding: 13px 30px 40px;
      text-align: center;
      &:nth-child(2) {
        height: 240px;
        margin-top: -30px;
      }
      &:nth-child(3) {
        height: 240px;
        margin-top: -79px;
      }
      &:nth-child(4) {
        height: 240px;
        margin-top: -30px;
      }  
      &:not(:first-child) {
        &::before {
          height: 100%;
        }
      }
    }
  }
}